@use 'style/mixins';
@use 'style/variables';

.missing-account {

  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__top-bar {
    grid-area: topBar;
    display: inline-flex;
    width: 100%;
    height: 3rem;
  }

  &__logo-container {
    display: flex;
    height: 100%;
    width: 10rem;
    margin-left: 1rem;
    align-items: center;
  }

  &__logout.ui.button:not(.dropdown) {
    display: flex;
    width: 8rem;
    height: 2rem;
    margin-left: auto;
    margin-right: 1rem;
    align-self: center;
    justify-content: center;
  }

  &__modal {
    color: variables.$tempoCharcoal;
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid variables.$gray300;
    box-shadow: -2px 12px 32px rgba(97, 96, 96, 0.25);
    border-radius: 8px;
    width: 500px;
    height: 590px;
    padding: 32px;
    margin-top: 80px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin-top: 56px;
  }

  &__h2 {
    font-weight: 600;
    margin-bottom: 4px;
  }

  &__h4 {
    font-weight: 500;
    margin-top: 0;
  }

  &__h5 {
    margin: 0;
    padding-top: 5px;
  }

  &__box-message {
    font-size: 12px;
    padding-top: 4px;
  }

  &__logo {
    margin-bottom: 16px;
  }

  &__box1 {
    display: flex;
    border: 1px solid rgb(26, 138, 58);
    border-radius: 4px;
    background-color: rgba(26, 138, 58, 0.1);
    width: 100%;
    margin-bottom: 4px;
    padding: 4px;
  }

  &__box2 {
    display: flex;
    border: 1px solid rgb(255, 165, 0);
    border-radius: 4px;
    background-color: rgba(255, 165, 0, 0.1);
    width: 100%;
    height: 80px;
    margin-top: 4px;
    padding: 4px;
  }

  &__box-icon {
    display: flex;
    padding: 8px;
    margin-right: 12px;
  }

  &__button.ui.button {
    width: 100%;
    height: 40px;
    background-color: variables.$tempoCharcoal;
    color: white;
    margin-top: 56px;
  }

  &__contact {
    margin-top: 8px;
    font-size: 12px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin-top: auto;
    border-top: 1px solid variables.$gray400;
    width: 100%;
    font-size: 12px;
    padding-top: 4px;
    color: variables.$gray600;
  }

  &__link {
    margin-left: 4px;
    font-weight: bold;
    text-decoration: underline;
    color: variables.$tempoCharcoal;
  }
}