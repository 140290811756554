/* FILE IS AUTOMATICALLY GENERATED FROM variables.less FOR USE IN SCSS FILES */

$weightLight : 300;
$weightRegular : 400;
$weightSemiBold : 600;
$weightBold : 700;
$weightExtraBold : 800;
$infoTableHeaderFontWeight : 600;
$itemNameMinWidth : 25rem;
$customColumnWidth : 10;
$itemMetricsWidth : 7;
$expectedFinishWidth : 7;
$scheduleBarWidth : 25%;
$baseFontSize : 14px;
$baseFontFamily : 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
$importantHeaderFontSize : 22px;
$importantMessageFontSize : 18px;
$lpPlainTextHeader : 1.2rem;
$infoTableHeaderFontSize : 1.1rem;
$subHeaderInfoMessage : 0.8rem;
$mini : 0.7rem;
$tiny : 0.8rem;
$small : 0.9rem;
$medium : 1rem;
$large : 1.2rem;
$big : 1.3rem;
$huge : 1.5rem;
$massive : 1.7rem;
$leftNavAnimationTime : 300ms;
$trickyTeal : #35d4cc;
$relightBlue : #4088df;
$sonicGreen : #3dba72;
$lemonTwist : #f8dd29;
$tigerOrange : #f28b1d;
$riskyRed : #db2828;
$pinkDrink : #ff8c9f;
$powerPurple : #695ea6;
$smartSlate : #8397ab;
$earlGray : #bfbfbf;
$tableBorder : #222426;
$alphaTableBorder : #22242626;
$lpFocusColor : #85b7d9;
$lpButtonColor : #3d546a;
$lpButtonHoverColor : #60768a;
$tempoCharcoal : #1d1d1b;
$tempoNovaGray2 : #e4e4e4;
$tempoLightGray : #8c8c8c;
$tempoRowHoverHighlightColor : #fafafa;
$tempoCellHoverHighlightColor : #f5f5f5;
$lpBrandBlue : #4088df;
$lpBrandSeattleBlue : #3d518c;
$lpBrandWhite : #ffffff;
$lpGray200 : #f5f5f5;
$lpGray500 : #bfbfbf;
$lpGray800 : #262626;
$lpTeal : #00b5ad;
$lpGreen : #3dba72;
$lpPurple : #8b7dcb;
$lpPink : #fb617f;
$lpRed : #db2828;
$lpOrange : #f28b1d;
$lpYellow : #f8dd29;
$blue50 : #f0f9ff;
$blue100 : #c6e7ff;
$blue200 : #97ccf7;
$blue300 : #6aa8eb;
$blue400 : #4088df;
$blue500 : #2c68b8;
$blue600 : #1d4e91;
$blue700 : #12396b;
$blue800 : #092345;
$blue900 : #04152b;
$teal50 : #defcfb;
$teal100 : #b1f2ef;
$teal200 : #7ae6e0;
$teal300 : #35d4cc;
$teal400 : #00c4bb;
$teal500 : #00b5ad;
$teal600 : #00a199;
$teal700 : #057d7d;
$teal800 : #005a5c;
$teal900 : #003f42;
$green50 : #edfff2;
$green100 : #d2fcde;
$green200 : #aee6c1;
$green300 : #8ad4a3;
$green400 : #61c788;
$green500 : #3dba72;
$green600 : #299459;
$green700 : #196e42;
$green800 : #0d472b;
$green900 : #08311f;
$yellow50 : #fefff0;
$yellow100 : #ffffcf;
$yellow200 : #fffca6;
$yellow300 : #fff67d;
$yellow400 : #ffee54;
$yellow500 : #f8dd29;
$yellow600 : #d1b219;
$yellow700 : #ab8b0c;
$yellow800 : #856603;
$yellow900 : #5e4501;
$orange50 : #fff9eb;
$orange100 : #ffeac2;
$orange200 : #ffd899;
$orange300 : #ffc370;
$orange400 : #ffac47;
$orange500 : #f28b1d;
$orange600 : #cc6a0e;
$orange700 : #a64c03;
$orange800 : #803500;
$orange900 : #592200;
$red50 : #fff2f0;
$red100 : #ffd7d1;
$red200 : #ffb1a8;
$red300 : #f5837a;
$red400 : #e8544f;
$red500 : #db2828;
$red600 : #b5181d;
$red700 : #8f0b14;
$red800 : #69030d;
$red900 : #42010a;
$pink50 : #fff8f8;
$pink100 : #fff0f1;
$pink200 : #ffdee1;
$pink300 : #ffb5bf;
$pink400 : #ff8c9f;
$pink500 : #fb617f;
$pink600 : #d44869;
$pink700 : #ad3253;
$pink800 : #87203f;
$pink900 : #61162f;
$purple50 : #fcfaff;
$purple100 : #f5f0ff;
$purple200 : #e7e1f8;
$purple300 : #d4cdf0;
$purple400 : #b2a7e0;
$purple500 : #8b7dcb;
$purple600 : #695ea6;
$purple700 : #494280;
$purple800 : #2e2a59;
$purple900 : #191733;
$gray50 : #ffffff;
$gray100 : #fafafa;
$gray200 : #f5f5f5;
$gray300 : #e8e8e8;
$gray400 : #d9d9d9;
$gray500 : #bfbfbf;
$gray600 : #8c8c8c;
$gray700 : #595959;
$gray800 : #262626;
$gray900 : #000000;
$slate50 : #eff7ff;
$slate100 : #e9f3fd;
$slate200 : #d8e6f4;
$slate300 : #c3d2e0;
$slate400 : #a7bacc;
$slate500 : #8397ab;
$slate600 : #60768a;
$slate700 : #3d546a;
$slate800 : #253344;
$slate900 : #0b151f;
$navy : #091540;
$janBradyGold : #f5910a;
$trueBlack : #000000;
$vinicioViolet : #114d72;
$readOnlyOpacity : 0.45;
$jiraBackgroundGray : #ebecf0;
$defaultCubicBezier : cubic-bezier(0.4, 0, 0.2, 1);
$decelerateCubicBezier : cubic-bezier(0, 0, 0.2, 1);
$minSizeMediaQuery : 1200px;