@use 'style/variables';
@use 'style/mixins';

.v2-dashboard-filter-modal {
  min-width: 40rem;
  max-width: 43rem;

  .actions > .reset-button.ui.button {
    margin: 0;
  }
}

.v2-dashboard-filter {
  display: grid;
  row-gap: 1rem;

  &__date-range-filter {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    &-dropdown {
      .field {
        margin-bottom: 0 !important;

        .ui.selection.dropdown {
          max-width: unset !important;
          width: 100%;
        }
      }
      &--filtered {
        .field {
          .ui.selection.dropdown {
            @include mixins.dropdownWithNonDefaultSelection;
          }
          .ui.selection.visible.dropdown > .text:not(.default) {
            background: variables.$slate700 !important;
            color: variables.$lpBrandWhite !important;
          }
        }
      }
    }
    &-dates {
      display: flex;
      column-gap: 0.5rem;
    }

    &-error {
      color: variables.$red500;
      background-color: variables.$red100;
      border: 1px solid variables.$red500;
      padding: 0.5rem;
    }
  }

  &__supporting-text {
    margin-bottom: 1rem;

    .inherited-location__item {
      margin-left: 1.3rem;
    }
  }

  &__section {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 3fr;

    &-label {
      justify-self: end;
      padding-right: 0.5rem;
    }

    &-content {
      &-collections {
        display: grid;
        grid-template-columns: auto auto auto;

        .field {
          margin-bottom: 0 !important;
        }
      }

      .ui.button {
        padding: 0.5rem 1rem !important;

        &.dropdown {
          &.folder-status-filter-dropdown,
          &.task-status-filter-dropdown {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background-color: variables.$lpBrandWhite;
            border: 1px solid variables.$gray400;
          }
        }
      }
    }
  }

  &__assigned-filter {
    max-width: unset !important;
    &--filtered {
      @include mixins.dropdownWithNonDefaultSelection;
    }

    &--group-name{
      display: flex;
      width: 100%;

      > svg {
        margin-left: auto;
      }
    }

    padding: 0.5rem 0 0.5rem 1rem !important;

    .dropdown-option-row {
      display: flex;
      align-items: center;

      &__disconnected {
        color: variables.$red500;
      }
    }

    .visible.menu.transition {
      .item {
        display: flex;
        align-items: center;

        .dropdown-option-row {
          display: flex;
          padding: 0;

          &__disconnected {
            color: variables.$red500;
          }
        }

        .anyone-icon {
          margin-right: 0.5rem;
        }

        > img {
          margin-top: unset;
          margin-bottom: unset;
        }
      }
    }

    &.ui.search.selection.dropdown {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.ui.search.selection.dropdown > input.search {
      padding: 0.5rem 1rem 0.5rem 2.3rem;
    }

    &.ui.search.selection.dropdown > .text {
      padding-left: 0;
      position: static;
      display: flex;
      width: 100%;

      > img {
        margin-top: unset;
        margin-bottom: unset;
      }
    }

    &.ui.search.selection.dropdown > .icon {
      float: none;
      line-height: inherit;
      margin: 0;
      padding: 0.5rem 1rem;
      position: static;
    }
  }
}
