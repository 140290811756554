@use 'style/mixins';
@use 'style/variables';
@use 'style/zindex';

@mixin unavailable {
  height: 2px;
  width: 2px;
  margin-right: 6px;
  margin-left: 4px;
  border-radius: 0px;
}

$plannedWorkNubBorderSize: 2px;

$timeOffNubColor: variables.$gray400;
$rollUpNubColor: variables.$gray700;
$rollUpRenderedAsLateNubColor: variables.$red500;
$emptyNubColor: variables.$gray500;
$unavailableNubColor: variables.$gray500;
$unavailableWithLateParentNubColor: variables.$red400;
$emptyWithLateParentNubColor: variables.$red400;

.workload-date-range {
  display: flex;
  align-items: flex-end;
  column-gap: 2px;

  &__planned_work_nub {
    background-color: transparent !important;

    &--time-off {
      border: solid $plannedWorkNubBorderSize $timeOffNubColor !important;
    }

    &--roll-up {
      border: solid $plannedWorkNubBorderSize $rollUpNubColor !important;
    }

    &--roll-up-rendered-as-late {
      border: solid $plannedWorkNubBorderSize $rollUpRenderedAsLateNubColor !important;
    }

    &--empty {
      border: solid $plannedWorkNubBorderSize $emptyNubColor !important;
    }

    .lp-nub &--unavailable {
      border: solid $plannedWorkNubBorderSize $unavailableNubColor !important;
    }

    .lp-nub &--unavailable-with-late-parent {
      border: solid $plannedWorkNubBorderSize $unavailableWithLateParentNubColor !important;
    }

    &--empty-with-late-parent {
      border: solid $plannedWorkNubBorderSize $emptyWithLateParentNubColor !important;
    }
  }

  &__nub {
    display: inline-flex;
    width: 10px;
    border-radius: 0px;
    margin-right: 2px;
    cursor: pointer;
    align-items: center;
    text-align: center;

    &-hover {
      @include mixins.overflowEllipsis;
      max-width: 22rem;
      z-index: zindex.$zIndexHeader;
    }

    &-risk-icon {
      width: 100%;
    }

    &--work-scheduled {
      height: 1.2rem;
    }

    &--time-off {
      background-color: $timeOffNubColor;
      height: 1.2rem;
    }

    &--roll-up {
      background-color: $rollUpNubColor;
    }

    &--roll-up-rendered-as-late {
      background-color: $rollUpRenderedAsLateNubColor;
    }

    &--empty {
      height: 2px;
      background-color: $emptyNubColor;
    }

    .lp-nub &--unavailable {
      @include unavailable;
      background-color: $unavailableNubColor;
    }

    .lp-nub &--unavailable-with-late-parent {
      @include unavailable;
      background-color: $unavailableWithLateParentNubColor;
    }

    &--empty-with-late-parent {
      height: 2px;
      background-color: $emptyWithLateParentNubColor;
    }

    &--work-scheduled-beyond-range {
      height: 1.2rem;

      &-icon {
        font-size: 1.2rem;
      }
    }

    &--zero-work-scheduled {
      height: 0.4rem;
    }
  }
}
