$grey1: #f6f6f6;
$grey2: #e4e4e4;
$grey3: #757574;
$grey4: #dce2e6;
$grey5: #939392;
$grey6: #b9b9b9;

$blue1: #1896ef;
$blue2: rgba(48, 179, 255, 0.1);
$blue3: #0061b2;

$green1: #dbf9d4;
$orange1: #db2b00;
$black1: #2b343c;
$black2: #1d1d1b;
$red1: #ffdeda;

$box-shadow:
  0px 8px 16px 0px rgba(29, 29, 27, 0.14),
  0px 0px 2px 0px rgba(29, 29, 27, 0.12);

$plan-card-width: 254px;
$plan-card-height: 256px;

$scenario-card-width: 440px;
$scenarios-card-width: 540px;
$scenarios-card-min-width: 400px;
$scenario-card-height: 600px;
$scenario-card-graph-height: 250px;
