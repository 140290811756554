@use 'style/mixins';
@use 'style/variables';

.unified-nav {
  & + .lp-main__content {
    width: calc(100% - var(--tempo-unified-nav-width));
  }

  .favorite-item {
    border-left: 6px solid transparent;
    padding-left: 1rem;
    &--name {
      @include mixins.overflowEllipsis;
    }
    &--sub-section {
      font-size: 90%;
      opacity: 50%;
    }
  }

  //this hides the expand/collspse icon in the Favorites CustomNavigationSection
  .tempo-arrow-down-on-custom {
    .open,
    .closed {
      display: none;
    }
  }
  /* This removes margin from the regular nav items so when we swap favorites for the CustomNavigationSection, the spacing is consistent */
  .tempo-nav:first-child {
    margin-bottom: 0;
    + .tempo-nav {
      margin-top: 0;
    }
  }
}

//This gets added when we open a Sematic Modal.  This hides the toggle button so it doesn't overlap the modal
body.hide-sidebar-toggler {
  .tempo-sidebar-toggle-container {
    display: none;
  }
}

// This resets the color because the title is a link
#tempo-tooltip-container {
  .tempo-nav-favorites {
    color: unset;
  }
}

#tempo-dropdown-container {
  .favorite-item {
    border-left: 3px solid transparent;
    padding-left: 0.5rem;
    &--sub-section {
      font-size: 90%;
      opacity: 50%;
    }
  }
}

#integrations-subnav,
#help-and-support-subnav,
#my-profile-subnav {
  &.tempo-navigation-dropdown-menu {
    position: absolute;
    visibility: hidden;
    width: 250px;
  }
  .external-link {
    display: flex;

    width: 100%;
    &--icon {
      margin-left: auto;
      opacity: 50%;
    }
  }
  .tempo-nav-item--academy {
    border-bottom: 1px solid variables.$tempoNovaGray2;
    &__has-outposts {
      border-top: 1px solid variables.$tempoNovaGray2;
    }
  }
  .my-profile,
  .switch-workspace {
    &--header {
      align-items: center;
      display: flex;
      gap: 7px;
    }

    &--name {
      @include mixins.overflowEllipsis;
      display: block;
      font-size: 90%;
      opacity: 50%;
      margin-left: 27px;
    }
  }
}
