@use 'style/mixins';
@use 'style/variables';

.lp-item-picker {
  &__content {
    &-body {
      display: flex;
      flex-direction: column;
      height: 26rem;
    }
    &-controls {
      display: grid;
      grid-template-columns: min-content 1fr min-content;
      column-gap: 1rem;

      &-move-up-tree {
        background: variables.$slate700 !important;
        color: variables.$lpBrandWhite !important;
      }

      &-include-done {
        width: 15rem;
      }

      &-search {
        width: 100%;
      }

      .checkbox {
        padding-top: 1.5rem;
        min-width: 11rem !important;
      }
    }

    &-parent {
      display: flex;
      align-items: center;
      background: variables.$gray200 !important;
      color: variables.$lpGray800 !important;
      padding: 0.5rem;
      opacity: 1 !important;
      text-align: left;
      @include mixins.overflowEllipsis;

      svg {
        opacity: 1 !important;
        margin: 0 0.5rem;
      }
    }

    &-table {
      background-color: variables.$lpBrandWhite;
      overflow: auto;
      overflow-x: hidden;
      margin-top: 1rem;
      border: 1px solid variables.$gray300;
      height: 100%;

      &-items {
        &-row {
          display: flex;
          align-items: center;
          padding-left: 1rem;

          .ui.fitted.checkbox {
            @include mixins.marginRight('sm');

            &.disabled.hidden {
              visibility: hidden;
            }
          }

          .ui.basic.fluid.button {
            display: flex;
            text-align: left !important;
            padding-left: 0.5rem !important;
            align-items: center;
            line-height: 1.3;

            .lp-item-picker__content-table-items-icon {
              @include mixins.marginRight('xs', !important);
            }

            &.disabled {
              opacity: 1 !important;
              color: variables.$lpGray800 !important;
              pointer-events: initial !important;

              &:focus {
                outline: none;
              }

              &.current-item-disable {
                opacity: 0.48 !important;
              }
            }
          }
        }

        &-row-name {
          @include mixins.overflowEllipsis();
          width: 90%;
          .team-name,
          .story-point-scheme-name {
            padding-left: 0.75rem;
            font-size: 80%;
          }

          .team-name--inherited{
            color: variables.$lpGray500;
          }
        }

        &-package {
          display: flex;
          text-align: left !important;
          padding-left: 2rem !important;
        }
      }
    }
  }
}
